import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { ElMessage } from "element-plus";
import { Document, Setting } from "@element-plus/icons-vue";
export default {
  components: {
    Document: Document,
    Setting: Setting
  },
  data: function data() {
    return {
      userInfo: {
        givenName: "",
        phoneNumber: ""
      },
      loading: false,
      tableIndex: 1,
      dataList1: [],
      dataList2: [],
      changePassword: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      total: 0,
      index: 1,
      size: 10,
      currentPage: 1,
      total2: 0,
      index2: 1,
      size2: 10,
      currentPage2: 1
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // 设置userinfo
    var userInfo = JSON.parse(window.localStorage.getItem("userInfo"));

    if (userInfo && userInfo !== "null") {
      this.userInfo = userInfo;
    }

    this.getData1(this.index, this.size);
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.size = val;
      this.getData1();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.index = val;
      this.currentPage = val;
      this.getData1();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      this.size2 = val;
      this.getData2();
    },
    handleCurrentChange2: function handleCurrentChange2(val) {
      this.index2 = val;
      this.currentPage2 = val;
      this.getData2();
    },
    select: function select(index) {
      if (index === "1-1") {
        this.tableIndex = 1;
        this.index = 1;
        this.size = 10;
        this.currentPage = 1;
        this.total = 0;
        this.getData1();
      } else if (index === "1-2") {
        this.tableIndex = 2;
        this.index2 = 1;
        this.size2 = 10;
        this.currentPage2 = 1;
        this.total2 = 0;
        this.getData2();
      } else if (index === "2-1") {
        this.changePassword = {
          currentPassword: "",
          newPassword: "",
          confirmPassword: ""
        };
        this.tableIndex = 3;
      }
    },
    getData1: function getData1() {
      var _this = this;

      this.$axios.get("/api/tenant/customerticket/my-paged-list?Index=" + this.index + "&Size=" + this.size).then(function (res) {
        _this.loading = false;

        if (res.code === 0) {
          _this.dataList1 = res.data.list;
          _this.total = res.data.total;
        } else {
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      });
    },
    getData2: function getData2() {
      var _this2 = this;

      this.$axios.get("/api/order/paged-list?Index=" + this.index2 + "&Size=" + this.size2).then(function (res) {
        _this2.loading = false;

        if (res.code === 0) {
          _this2.dataList2 = res.data.list;
          _this2.total2 = res.data.total;
        } else {
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      });
    },
    // 修改密码
    submitPassword: function submitPassword() {
      var _this3 = this;

      if (!this.changePassword.currentPassword) {
        ElMessage({
          message: "请填写原密码",
          type: "warning"
        });
        return;
      }

      if (!this.changePassword.newPassword) {
        ElMessage({
          message: "请填写新密码",
          type: "warning"
        });
        return;
      }

      if (!this.changePassword.confirmPassword) {
        ElMessage({
          message: "请填写确认密码",
          type: "warning"
        });
        return;
      }

      this.loading = true;
      this.$axios.post("/api/user/current/password", this.changePassword).then(function (res) {
        _this3.loading = false;

        if (res.code === 0) {
          ElMessage({
            message: res.message || "修改成功",
            type: "success"
          });
          window.localStorage.clear();

          _this3.$router.replace("home");

          window.localStorage.setItem('IsLoginVisible', true);
        } else {
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      });
    },
    goHome: function goHome() {
      this.$router.push("home");
    },
    // 退出登录
    logout: function logout() {
      window.localStorage.clear();
      this.$router.replace("home");
    }
  }
};